import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="personality" mdxType="Subpage">
      <h3>{`Personality`}</h3>
      <p>{`As you get to know your users don't forget that your interactive project can also have its own personality.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 3 from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/designing-for-emotion/9780133052954/"
          }}>{`Walter`}</a>{`.`}</p>
      </Callout>
    </Subpage>
    <Subpage slug="emotional-engagement" mdxType="Subpage">
      <h3>{`Emotional Engagement`}</h3>
      <p>{`Read about how we can use a variety of factors to add emotional appeal to our interactive applications.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 4 from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/designing-for-emotion/9780133052954/"
          }}>{`Walter`}</a>{`.`}</p>
      </Callout>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      